export default {
  ok: {
    en: 'Okay',
  },
  cancel: {
    en: 'Cancel',
  },
  close: {
    en: 'Close',
  },
  continue: {
    en: 'Continue',
  },
  connect: {
    en: 'Connect wallet',
  },
  processing: {
    en: 'Processing',
  },
  preparing: {
    en: 'Preparing',
  },
  loading: {
    en: 'Loading',
  },
  deposit: {
    en: 'Deposit',
  },
  makeDeposit: {
    en: 'Make a deposit',
  },
  openCase: {
    en: 'Open lootbox',
  },
  signMessage: {
    en: 'Sign message',
  },
  switchChain: {
    en: 'Switch chain',
  },
  viewTransaction: {
    en: 'View Transaction In Explorer',
  },
  spendCredits: {
    en: 'Spend my Credits',
  },
  unstake: {
    en: 'Unstake',
  },
}
